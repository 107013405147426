<template>
  <div class="layout">
    <div class="recruit"></div>
    <div class="layout1">
      <el-input
          placeholder="请输入内容"
          style="width: 1200px"
          v-model="search">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-button class="button" type="primary">搜 &nbsp; 索</el-button>
    </div>
<div v-for="(i,index) in recruit" :key = 'i.id'>
  <div class="layout2" @click="outerVisiblecurrent(i,index)">
    <div class="card">
      <div class="main">{{ i.job }}</div>
      <div class="main2">{{i.dept}}</div>
      <div class="main3"><span>工作地点:{{ i.place}}</span> <span class="span1">招聘人数：{{ i.needNum }}</span><span
          class="span1">更新时间：2022-04-18</span></div>
      <div class="location"> <img  class="img" src="../../assets/img/recruit/sc1.png" /> 投递简历</div>
    </div>
  </div>
</div>
    <el-dialog title="招聘详情" :visible.sync="outerVisible">
      招聘职位：{{ current.job }}<br>
      招聘部门：{{current.dept}}<br>
      工作地点：{{ current.place}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;招聘人数：{{ current.needNum }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;更新时间：{{ current.createTime }}<br>
      <br>
      岗位职责:<br>
      <div v-html="current.responsibility"></div>
      <br>
      职位要求<br>
      <div v-html="current.require"></div>
      <div slot="footer" class="dialog-footer">
        <el-upload
            class="upload-demo"
            :action="upload"
            :on-change="handleChange"
            :file-list="fileList"
            :on-success="uploadSuccess"
        >
          <el-button type="primary" size="small">上传简历</el-button>
        </el-upload>

      </div>
    </el-dialog>

  </div>
</template>
<script>
import {get, post} from '../../utils/http.js'
import config from '../../config/index'
export default {
  name: "recruit",

  components: {
  },
  data() {
    return {
      upload:'',
      search: '',
      outerVisible: false,
      fileList: [],
      recruit:[],
      current:{},
      recruitId:'',
      companyId:'',
    }


  },
  created() {
    this.toTop();
    this.lode();
    this.upload = config.apiRoot+"/common/upload";
  },
  methods: {
    lode() {
      let data = {
        //请求参数
        username: "t1",
        password: "abc123..",
        code: "zjqj"
      }
      let url = "/login"
      post(url, data).then(res => {
        localStorage.setItem("token", res.token);
        this.team()
      })
    },
    team() {
      let teamurl = "/portal/recruit/listByCompanyId?companyId=6"
      get(teamurl, null).then(res => {
        console.log(res)
        this.recruit = res
      })
    },

    outerVisiblecurrent(value,index){
      console.log(value,index)
      this.current = value
      this.recruitId = value.id
      this.companyId = value.companyId
      this.outerVisible = true
      this.fileList =[]

    },
    uploadSuccess(res) {
      console.log(res)
      let url='/portal/resume'
      let data = {}
      data.recruitId = this.recruitId
      data.companyId = this.companyId
      data.resume = res.fileName
      post(url, data).then(res => {
        localStorage.setItem("token", res.token);
        this.$message({
          message: '恭喜你，简历投递成功',
          type: 'success'
        });
      })
      this.fileList =[]
      this.outerVisible = false
    },
    handleChange(){

    },
    toTop() {
      let time = setInterval(() => {
        document.documentElement.scrollTop += -50;
        if (document.documentElement.scrollTop === 0) {
          clearInterval(time);
        }
      }, 10);
    },
  }
}

</script>

<style lang="less" scoped>
.layout {
  padding-top: 70px;
  width: 100%;
  min-height: 760px;
  background-color: #f0f5fa;

}
.recruit{
  width: 1300px;
  height: 240px;
  margin: 0 auto;
  background: url("../../assets/img/recruit/recruit1.png") no-repeat;

}
.layout1 {
  width: 1300px;
  height: 40px;
  margin: 20px auto 0;

  .button {
    font-size: 16px;
    position: relative;
    left: -4px;
    width: 100px;
  }

}

.layout2 {
  width: 1298px;
  height: 130px;
  margin: 20px auto 0;
  border-radius: 5px;
  background-color: white;

  .card {
    width: 1298px;
    height: 130px;
    position: absolute;
    margin-left: 20px;
    padding-top: 15px;

    .main {
      width: 1000px;
      font-size: 18px;
      margin-top: 5px;
      color: #424a5e
    }

    .main2 {
      width: 1000px;
      font-size: 15px;
      margin-top: 10px;
      color: #424a5e

    }

    .main3 {
      width: 1000px;
      font-size: 12px;
      margin-top: 15px;
      color: #7e8586;

    }

    .span1 {
      padding-left: 30px;
    }
    .location{
      float: right;
      position: relative;
      width: 60px;
      top: -100px;
      right: 46px;
      height: 60px;
    }
    .img{
      width: 60px;
      height: 60px;
    }
  }
}

</style>